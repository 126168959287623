* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.me-auto a {
  list-style: none;
  text-decoration: none;
  color: silver;
  margin-right: 10px;
}
.me-auto a:hover {
  color: #777;
}

main {
  width: 80%;
  height: 650px;
  margin: 20px auto;
}

.books {
  margin: 20px auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}
.bookCart {
  width: 350px;
  margin: 20px auto;
  background-color: #7772;
  border-radius: 15px;
  padding: 10px;
}
.bk {

    display: flex;
    justify-content: space-between; 
}
.cbook{
    display: grid;
grid-template-columns: repeat(4, 20px);
    width: 80px;

}
.cbook button{
    border: none;
    width: 6px;
    height: 10px;
}

.center{
    width: 350px;
    height: auto;
    margin: 20px auto;
    background-color: #2225;
    padding: 10px;
    border-radius: 15px;
}